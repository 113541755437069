body {
  margin: 0;
  font-family: "Source Sans Pro";
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    display: none;
  }
  & ::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: white;
  }
  &::-webkit-scrollbar {
    width: 6px;
    background-color: #ffffff;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    max-height: 10px;
    background-color: #e9e8e8;
  }
}
body {
  scrollbar-width: thin;
}

.chat-fc-form-outer div.fc-form ul li {
  list-style: none;
  position: relative;
  margin-bottom: 35px !important;
}
.chat-fc-form-outer div.fc-form ul li label {
  position: absolute;
  top: -20px !important;
  font-weight: lighter;
  margin-right: 8px;
  margin-left: 0px !important;
  padding: 0px !important;
  background-color: white;
  border-radius: 3px;
}
/* 
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
/*# sourceMappingURL=index.css.map */
